import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { Constants } from './app/shared/constants';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (false) {
  Sentry.init({
    dsn: environment.sentryKey,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments application to monitor its
      // performance, including custom Angular routing instrumentation
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation
      }),
      new Sentry.Replay()
    ],
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: Constants.environmentsForErrorTracing,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // values range from 0 to 1, 0.2 signifies 20% of transactions will be captured.
    tracesSampleRate: 1.0
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { Account } from 'src/app/auth/models/user.model';
import { AuthService } from 'src/app/auth/services';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { CommonUtils } from 'src/app/shared/utils';

@Component({
  selector: 'app-header-elements',
  templateUrl: './header-elements.component.html',
  styleUrls: ['./header-elements.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HeaderElementsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() override currentUser!: Account | null;

  @ViewChild('logoutDialog') logoutDialog!: TemplateRef<any>;

  userInitials!: string;
  dialogRef!: MatDialogRef<any>;

  constructor(
    private readonly authService: AuthService,
    private readonly cdr: ChangeDetectorRef,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['currentUser']?.currentValue) {
      this.currentUser = changes?.['currentUser']?.currentValue;
      this.setInitials(this.currentUser?.firstName, this.currentUser?.lastName);
    }
  }

  ngOnInit(): void {
    this.authService
      .getCurrentUser$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: Account | null) => {
          this.currentUser = res;
          this.cdr.detectChanges();
        }
      });
  }

  setInitials(firstName?: string, lastName?: string): string {
    return !this.currentUser?.lastName
      ? CommonUtils.getInitialsUsingFullName(firstName)
      : CommonUtils.getInitials(firstName, lastName);
  }

  openLogoutConfirmationDialog(): void {
    this.dialogRef = this.dialog.open(this.logoutDialog, {
      width: '400px'
    });
  }

  closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  confirmLogout(): void {
    this.closeDialog();
    this.logout();
  }

  logout(): void {
    this.authService.logOut();
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import { ROUTER_PATHS } from 'src/app/shared/constants';
import { API_URL } from 'src/app/shared/constants/api-url.constants';
import { CBGetResponse, CBResponse, ServerMessage } from 'src/app/shared/models';
import { LocalStorageService, StorageItem } from 'src/app/shared/services/local-storage.service';
import { RefreshTokenService } from 'src/app/shared/services/refresh-token.service';
import {
  ForgotPasswordFinishRequestParams,
  ForgotPasswordInitRequestParams,
  LoginParams,
  LoginResponse,
  ProfileBeforeSignUpParams,
  SignUpAccountInfoParams,
  SignUpType,
  ThirdPartyLoginParams
} from '../models';
import { Account } from '../models/user.model';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { SchoolLocations } from 'src/app/pages/room-and-location-management/models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn$ = new BehaviorSubject<boolean>(!!this.localStorageService.getItem(StorageItem.AuthToken));
  currentUser!: Account | null;
  currentUserSubject: BehaviorSubject<Account | null> = new BehaviorSubject(this.currentUser);
  userRole!: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly refreshTokenService: RefreshTokenService,
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
    private readonly socialAuthService: SocialAuthService
  ) {}

  login(loginParams: LoginParams): Observable<LoginResponse> {
    return this.httpClient
      .post<LoginResponse>(`${API_URL.account.tokeAuth}/${API_URL.account.login}`, loginParams)
      .pipe(
        map((res) => {
          this.refreshTokenService.storeAuthTokens(res);
          this.refreshTokenService.startRefreshTokenTimer(res.result.expireInSeconds);
          this.isLoggedIn$.next(true);
          return res;
        })
      );
  }

  loginWithThirdParty(loginParams: ThirdPartyLoginParams): Observable<any> {
    return this.httpClient
      .post<any>(`${API_URL.account.tokeAuth}/${API_URL.account.loginWithThirdParty}`, loginParams)
      .pipe(
        map((res) => {
          this.refreshTokenService.storeAuthTokens(res);
          this.refreshTokenService.startRefreshTokenTimer(res.result.expireInSeconds);
          this.isLoggedIn$.next(true);
          return res;
        })
      );
  }

  get isLoggedIn(): boolean {
    return this.isLoggedIn$.getValue();
  }

  onForgotPasswordInit(params: ForgotPasswordInitRequestParams): Observable<ServerMessage> {
    return this.httpClient.post<ServerMessage>(
      `${API_URL.services}/${API_URL.app}/${API_URL.account.root}/${API_URL.account.forgotPassword.init}`,
      params
    );
  }

  onForgotPasswordFinish(params: ForgotPasswordFinishRequestParams): Observable<void> {
    return this.httpClient.post<void>(
      `${API_URL.services}/${API_URL.app}/${API_URL.account.root}/${API_URL.account.forgotPassword.finish}`,
      params
    );
  }

  registerUser<ReturnType>(params: SignUpAccountInfoParams): Observable<ReturnType> {
    return this.httpClient.post<ReturnType>(
      `${API_URL.services}/${API_URL.app}/${API_URL.account.root}/${API_URL.account.register}`,
      params
    );
  }

  saveBasicProfileInfo(params: ProfileBeforeSignUpParams): Observable<void> {
    return this.httpClient.post<void>(
      `${API_URL.services}/${API_URL.app}/${API_URL.account.root}/${API_URL.account.createUserDetails}`,
      params
    );
  }

  saveStudentInfo(params: SignUpType): Observable<void> {
    return this.httpClient.post<void>(
      `${API_URL.services}/${API_URL.app}/${API_URL.account.root}/${API_URL.account.createUserDetails}`,
      params
    );
  }

  getAllLocations(): Observable<CBResponse<SchoolLocations>> {
    return this.httpClient.get<CBResponse<SchoolLocations>>(
      `${API_URL.services}/${API_URL.app}/${API_URL.schoolLocations.root}/${API_URL.crud.getAll}`
    );
  }

  setCurrentUser$(value: Account | null) {
    this.currentUserSubject.next(value);
  }

  getCurrentUser$(): Observable<Account | null> {
    return this.currentUserSubject.asObservable();
  }

  getCurrentUser(fromDb = false): Observable<Account | null> {
    if (fromDb || !this.currentUser?.dependentId) {
      const storedUser = this.localStorageService.getItem(StorageItem.CurrentUser);

      if (storedUser) {
        this.currentUser = storedUser as Account;
        this.currentUserSubject.next(storedUser as Account);
      }

      return this.httpClient
        .get<CBGetResponse<Account>>(
          `${API_URL.services}/${API_URL.app}/${API_URL.profile.root}/${API_URL.profile.getCurrentUserProfileForEdit}`
        )
        .pipe(
          map((res) => {
            this.currentUser = res.result as Account;
            this.localStorageService.setItem(StorageItem.CurrentUser, res.result);
            this.setCurrentUser$(res.result);
            return res.result as Account;
          }),
          catchError((err) => {
            this.logOut();
            return of(null);
          })
        );
    }
    return of(this.currentUser);
  }

  logOut(): void {
    this.socialAuthService.signOut();
    this.localStorageService.removeItem(StorageItem.AuthToken);
    this.localStorageService.removeItem(StorageItem.CurrentUser);
    this.localStorageService.removeItem(StorageItem.RefreshToken);
    this.currentUser = null;
    this.setCurrentUser$(null);
    this.isLoggedIn$.next(false);
    this.refreshTokenService.stopRefreshTokenTimer();
    this.router.navigate([ROUTER_PATHS.auth.root]);
  }
}

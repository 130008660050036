import { Constants } from './app.constants';
import { ROUTER_PATHS } from './router.constants';

export const MenuConfig = [
  {
    id: ROUTER_PATHS.dashboard.root,
    title: 'Dashboard',
    icon: Constants.staticImages.icons.dashboard,
    routerLink: ROUTER_PATHS.dashboard.root,
    isAccessible: true,
    parentRoute: null,
    isOpen: false,
    moduleKeys: ['dashboard'],
    subMenuOptions: [],
    authorities: [Constants.roles.ALL]
  },
  {
    id: ROUTER_PATHS.schedule.root,
    title: 'Schedule',
    icon: Constants.staticImages.icons.scheduleIcon,
    routerLink: `${ROUTER_PATHS.schedule.root}`,
    isAccessible: true,
    parentRoute: null,
    isOpen: false,
    moduleKeys: ['schedule'],
    subMenuOptions: [],
    authorities: [
      Constants.roles.ADMIN,
      Constants.roles.DESK_MANAGER,
      Constants.roles.INSTRUCTOR,
      Constants.roles.SUPERVISOR
    ]
  },
  {
    id: ROUTER_PATHS.roomAndLocation.root,
    title: 'Room & Location Management',
    icon: Constants.staticImages.icons.roomIcon,
    routerLink: ROUTER_PATHS.roomAndLocation.root,
    isAccessible: true,
    parentRoute: null,
    isOpen: false,
    moduleKeys: ['Room & Location Management'],
    subMenuOptions: [],
    authorities: [Constants.roles.ADMIN]
  },
  {
    id: ROUTER_PATHS.members.root,
    title: 'Members',
    icon: Constants.staticImages.icons.memberIcon,
    routerLink: ROUTER_PATHS.members.root,
    isAccessible: true,
    parentRoute: null,
    isOpen: false,
    moduleKeys: ['members'],
    authorities: [
      Constants.roles.ADMIN,
      Constants.roles.DESK_MANAGER,
      Constants.roles.INSTRUCTOR,
      Constants.roles.SUPERVISOR
    ],
    subMenuOptions: [
      {
        id: ROUTER_PATHS.members.students,
        title: 'Students',
        icon: '',
        routerLink: `${ROUTER_PATHS.members.root}/${ROUTER_PATHS.members.students}`,
        isAccessible: true,
        isOpen: false,
        parentRoute: ROUTER_PATHS.members.root,
        authorities: [
          Constants.roles.ADMIN,
          Constants.roles.DESK_MANAGER,
          Constants.roles.INSTRUCTOR,
          Constants.roles.SUPERVISOR
        ]
      },
      {
        id: ROUTER_PATHS.members.instructors,
        title: 'Instructors',
        icon: '',
        routerLink: `${ROUTER_PATHS.members.root}/${ROUTER_PATHS.members.instructors}`,
        isAccessible: true,
        isOpen: false,
        parentRoute: ROUTER_PATHS.members.root,
        authorities: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER, Constants.roles.SUPERVISOR]
      },
      {
        id: ROUTER_PATHS.members.supervisors,
        title: 'Supervisors',
        icon: '',
        routerLink: `${ROUTER_PATHS.members.root}/${ROUTER_PATHS.members.supervisors}`,
        isAccessible: true,
        isOpen: false,
        parentRoute: ROUTER_PATHS.members.root,
        authorities: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER]
      },
      {
        id: ROUTER_PATHS.members.deskManagers,
        title: 'Desk Managers',
        icon: '',
        routerLink: `${ROUTER_PATHS.members.root}/${ROUTER_PATHS.members.deskManagers}`,
        isAccessible: true,
        isOpen: false,
        parentRoute: ROUTER_PATHS.members.root,
        authorities: [Constants.roles.ADMIN]
      }
    ]
  },
  {
    id: ROUTER_PATHS.visits.root,
    title: 'Visits/Scheduling',
    icon: Constants.staticImages.icons.visitScheduling,
    routerLink: ROUTER_PATHS.visits.root,
    isAccessible: true,
    parentRoute: null,
    isOpen: false,
    moduleKeys: ['visits'],
    subMenuOptions: [],
    authorities: [Constants.roles.USER]
  },
  {
    id: ROUTER_PATHS.plansAndPasses.root,
    title: 'Plans & Passes',
    icon: Constants.staticImages.icons.fileCheck,
    routerLink: ROUTER_PATHS.plansAndPasses.root,
    isAccessible: true,
    parentRoute: null,
    isOpen: false,
    moduleKeys: ['plans-and-passes'],
    subMenuOptions: [],
    authorities: [Constants.roles.USER]
  },
  {
    id: ROUTER_PATHS.scheduleClasses.root,
    title: 'Schedule Classes',
    icon: Constants.staticImages.icons.scheduleIcon,
    routerLink: ROUTER_PATHS.scheduleClasses.root,
    isAccessible: true,
    parentRoute: null,
    isOpen: false,
    moduleKeys: ['schedule-classes'],
    authorities: [Constants.roles.USER],
    subMenuOptions: [
      {
        id: ROUTER_PATHS.scheduleClasses.introductoryLesson,
        title: 'Introductory Lesson',
        icon: '',
        routerLink: `${ROUTER_PATHS.scheduleClasses.root}/${ROUTER_PATHS.scheduleClasses.introductoryLesson}`,
        isAccessible: true,
        isOpen: false,
        parentRoute: ROUTER_PATHS.scheduleClasses.root,
        authorities: [Constants.roles.USER]
      },
      {
        id: ROUTER_PATHS.scheduleClasses.groupClasses,
        title: 'Group Classes',
        icon: '',
        routerLink: `${ROUTER_PATHS.scheduleClasses.root}/${ROUTER_PATHS.scheduleClasses.groupClasses}`,
        isAccessible: true,
        isOpen: false,
        parentRoute: ROUTER_PATHS.scheduleClasses.root,
        authorities: [Constants.roles.USER]
      },
      {
        id: ROUTER_PATHS.scheduleClasses.summerCamp,
        title: 'Summer Camp',
        icon: '',
        routerLink: `${ROUTER_PATHS.scheduleClasses.root}/${ROUTER_PATHS.scheduleClasses.summerCamp}`,
        isAccessible: true,
        isOpen: false,
        parentRoute: ROUTER_PATHS.scheduleClasses.root,
        authorities: [Constants.roles.USER]
      }
    ]
  },
  {
    id: ROUTER_PATHS.document.root,
    title: 'Document',
    icon: Constants.staticImages.icons.documentIcon,
    routerLink: ROUTER_PATHS.document.root,
    isAccessible: true,
    parentRoute: null,
    isOpen: false,
    moduleKeys: ['document'],
    subMenuOptions: [],
    authorities: [Constants.roles.USER]
  },
  {
    id: ROUTER_PATHS.referral.root,
    title: 'Referral',
    icon: Constants.staticImages.icons.referral,
    routerLink: ROUTER_PATHS.referral.root,
    isAccessible: true,
    parentRoute: null,
    isOpen: false,
    moduleKeys: ['referral'],
    subMenuOptions: [],
    authorities: [Constants.roles.USER]
  },
  {
    id: ROUTER_PATHS.help.root,
    title: 'Help',
    icon: Constants.staticImages.icons.help,
    routerLink: ROUTER_PATHS.help.root,
    isAccessible: true,
    parentRoute: null,
    isOpen: false,
    moduleKeys: ['help'],
    subMenuOptions: [],
    authorities: [Constants.roles.USER]
  },
  {
    id: ROUTER_PATHS.settings.root,
    title: 'Settings',
    icon: Constants.staticImages.icons.gearWhite,
    routerLink: ROUTER_PATHS.settings.root,
    isAccessible: true,
    parentRoute: null,
    isOpen: false,
    moduleKeys: ['settings'],
    authorities: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER],
    subMenuOptions: [
      {
        id: ROUTER_PATHS.settings.plan,
        title: 'Plan',
        icon: '',
        routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.plan}`,
        isAccessible: true,
        isOpen: false,
        parentRoute: ROUTER_PATHS.settings.root,
        authorities: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER]
      },
      {
        id: ROUTER_PATHS.settings.passes,
        title: 'Passes',
        icon: '',
        routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.passes}`,
        isAccessible: true,
        isOpen: false,
        parentRoute: ROUTER_PATHS.settings.root,
        authorities: [Constants.roles.ADMIN]
      },

      {
        id: ROUTER_PATHS.settings.groupClass,
        title: 'Group Class',
        icon: '',
        routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.groupClass}`,
        isAccessible: true,
        isOpen: false,
        parentRoute: ROUTER_PATHS.settings.root,
        authorities: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER]
      },
      {
        id: ROUTER_PATHS.settings.summerCamp,
        title: 'Summer Camp',
        icon: '',
        routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.summerCamp}`,
        isAccessible: true,
        isOpen: false,
        parentRoute: ROUTER_PATHS.settings.root,
        authorities: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER]
      },
      {
        id: ROUTER_PATHS.settings.revenueCategory,
        title: 'Revenue Category',
        icon: '',
        routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.revenueCategory}`,
        isAccessible: true,
        isOpen: false,
        parentRoute: ROUTER_PATHS.settings.root,
        authorities: [Constants.roles.ADMIN]
      },
      {
        id: ROUTER_PATHS.settings.document,
        title: 'Document',
        icon: '',
        routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.document}`,
        isAccessible: true,
        isOpen: false,
        parentRoute: ROUTER_PATHS.settings.root,
        authorities: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER]
      },
      {
        id: ROUTER_PATHS.settings.referral,
        title: 'Referral',
        icon: '',
        routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.referral}`,
        isAccessible: true,
        isOpen: false,
        parentRoute: ROUTER_PATHS.settings.root,
        authorities: [Constants.roles.ADMIN]
      },
      {
        id: ROUTER_PATHS.settings.help,
        title: 'Help',
        icon: '',
        routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.help}`,
        isAccessible: true,
        isOpen: false,
        parentRoute: ROUTER_PATHS.settings.root,
        authorities: [Constants.roles.ADMIN]
      }
    ]
  }
];
